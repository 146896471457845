import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { gsap } from 'gsap'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import RegisterForm from './register-consumer'

import "./_header.scss"
import "./_navigation.scss"
import "./_register.scss"
import "../../../assets/styles/global.scss"

const svgLogo = `<svg xmlns="http://www.w3.org/2000/svg" width="145.719" height="18.504" viewBox="0 0 145.719 18.504">
<g id="Logo" transform="translate(-237.026 641.377)">
  <path id="Path_1" data-name="Path 1" d="M247.534-627.51l-3.588,8.127h-2.819l-4.1-17.94h2.742l3.152,14.147,3.581-7.988h2.16l3.587,8.039,3.152-14.2h2.64l-4.126,17.94H251.1Z" transform="translate(0 -3.746)" fill="#404a3d"/>
  <path id="Path_2" data-name="Path 2" d="M562.877-619.383H550.883v-17.94H562.8v2.435h-9.175v3.725h8.534v2.435h-8.534v6.911h9.252Z" transform="translate(-290.049 -3.746)" fill="#404a3d"/>
  <path id="Path_3" data-name="Path 3" d="M753.927-637.323h2.742v15.505h8.175v2.435H753.927Z" transform="translate(-477.69 -3.746)" fill="#404a3d"/>
  <path id="Path_4" data-name="Path 4" d="M933.995-637.323h2.742v15.505h8.175v2.435H933.995Z" transform="translate(-644.099 -3.746)" fill="#404a3d"/>
  <path id="Path_5" data-name="Path 5" d="M1106.162-633.509l-1.64-.487c-2.358-.692-3.152-1.307-3.152-2.563,0-1.461,1.41-2.486,3.357-2.486,1.893,0,3.486.871,3.583,3.076h2.67v-.205c-.051-3.127-2.614-5.2-6.253-5.2-3.537,0-6.048,2.05-6.048,4.946,0,2.358,1.281,3.767,4.408,4.716l1.589.487c2.332.718,3.767,1.563,3.767,3.229,0,1.768-1.768,2.768-3.691,2.768-2.2,0-3.954-1.288-4.234-4.1h-2.711c.307,4.69,3.409,6.458,6.92,6.458,3.716,0,6.484-2.127,6.484-5.305C1111.211-630.869,1109.622-632.484,1106.162-633.509Z" transform="translate(-795.485 0)" fill="#404a3d"/>
  <path id="Path_6" data-name="Path 6" d="M1320.183-628.729v9.346h-2.742v-17.94h10.995v2.435h-8.252v3.725h7.484v2.435Z" transform="translate(-998.458 -3.746)" fill="#404a3d"/>
  <path id="Path_7" data-name="Path 7" d="M1488.051-632.176c0-5.356,3.614-9.2,8.483-9.2,4.921,0,8.509,3.819,8.509,9.2,0,5.587-3.742,9.3-8.509,9.3C1491.742-622.873,1488.051-626.564,1488.051-632.176Zm14.147,0c0-3.665-2.255-6.638-5.664-6.638s-5.638,2.947-5.638,6.638c0,3.819,2.307,6.74,5.638,6.74C1499.917-625.436,1502.2-628.332,1502.2-632.176Z" transform="translate(-1156.126 0)" fill="#404a3d"/>
  <path id="Path_8" data-name="Path 8" d="M1762.325-627.507h-2.973v8.124h-2.717v-17.94h7c3.024,0,5.408,1.845,5.408,4.818a4.713,4.713,0,0,1-1.41,3.537,5.038,5.038,0,0,1-2.306,1.23l4.921,8.355h-3.2Zm-2.973-2.409h4.126a2.438,2.438,0,0,0,2.717-2.563,2.5,2.5,0,0,0-2.794-2.409h-4.049Z" transform="translate(-1404.336 -3.746)" fill="#404a3d"/>
  <path id="Path_9" data-name="Path 9" d="M1969.138-637.323h6.023a8.328,8.328,0,0,1,8.3,8.739c0,5.767-3.87,9.2-8.252,9.2h-6.074Zm6.023,15.505c2.973,0,5.459-2.614,5.459-6.766,0-3.819-2.588-6.3-5.536-6.3h-3.2v13.071Z" transform="translate(-1600.719 -3.746)" fill="#404a3d"/>
</g>
</svg>
`

const MENU_QUERY = graphql`
  query {
    allWordpressWpApiMenusMenusItems(filter: {name: {eq: "Primary"}}) {
      edges {
        node {
          items {
            title
            object_slug
            url
          }
        }
      }
    }
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true, isRegisterToggleOn: true,scrolling: false};
    // this.state = {isRegisterToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.navToggle = this.navToggle.bind(this);
    this.registerToggle = this.registerToggle.bind(this);
    this.headerScroll = this.headerScroll.bind(this);

    this.tl = gsap.timeline({
      paused: true,
      reversed: true
    });

    this.tlReg = gsap.timeline({
      paused: true,
      reversed: true
    });
    this.tlCC = gsap.timeline({
      paused: true,
      reversed: true
    });
  }
  
  componentDidMount(){
    window.addEventListener('scroll', this.headerScroll);

    // Navigation Toggle Transitions
    this.tl.to('nav',{autoAlpha:1, duration: 0.25})
    this.tl.to('ul',{y:0,opacity:1,duration:0.25})
    // Register Toggle Transition
    this.tlReg.to('.register',{autoAlpha:1, duration: 0.25})
    // Color Change Transition
    this.tlCC.to('.logo svg *',{fill:'#fff',duration:0.5}, "-=.5")
    this.tlCC.to('button',{color:'#fff',duration:0.5}, "-=.5")

    const headerHeight = document.querySelector('header').clientHeight
    document.documentElement.style.setProperty('--headerHeight', headerHeight + "px");

    this.targetReg = document.querySelector('.register');
    this.targetNav = document.querySelector('nav');
  }

  componentWillUnmount(){
    enableBodyScroll(this.targetNav)
  }

  navToggle() {
		this.setState(function(prevState) {
			return {isToggleOn: !prevState.isToggleOn};
    });
    this.tl.reversed() ? this.tl.play() : this.tl.reverse();
    // Here we are seeing if the color of the has changed and if not, changing it
    if(!this.tlReg.reversed() || !this.tl.reversed()){
      this.tlCC.play()
      disableBodyScroll(this.targetNav)
    } else {
      enableBodyScroll(this.targetNav)
      this.tlCC.reverse()
    }
    // Here we are seeing if the registration page is open, and if so, closing it and changing it's state
    if(!this.tlReg.reversed()){
      this.tlReg.reverse()
      this.setState(function(prevState) {
        return {isRegisterToggleOn: !prevState.isRegisterToggleOn};
      });
    }
    if(this.state.scrolling === true){
      this.setState(function(){
        this.setState({scrolling: false});
      })
    }
  }

  registerToggle() {
		this.setState(function(prevState) {
			return {isRegisterToggleOn: !prevState.isRegisterToggleOn};
    });
    this.tlReg.reversed() ? this.tlReg.play() : this.tlReg.reverse();
    // Here we are seeing if the color of the has changed and if not, changing it
    if(!this.tlReg.reversed() || !this.tl.reversed()){
      this.tlCC.play()
      disableBodyScroll(this.targetReg)
    } else {
      enableBodyScroll(this.targetReg)
      this.tlCC.reverse()
    }
    // Here we are seeing if the nav is open, and if so, closing it and changing it's state
    if(!this.tl.reversed()){
      this.tl.reverse()
      this.setState(function(prevState) {
        return {isToggleOn: !prevState.isToggleOn};
      });
    }
    if(this.state.scrolling === true){
      this.setState(function(){
        this.setState({scrolling: false});
      })
    }
  }
  
  headerScroll(event) {
    if (window.scrollY > 50) {
        this.setState({scrolling: true});
    } else {
        this.setState({scrolling: false});
    }
  }
  
  render(){
    return (
    <>
      <nav>
      <StaticQuery
        query={MENU_QUERY}
        render={({ allWordpressWpApiMenusMenusItems }) => {
          if (allWordpressWpApiMenusMenusItems) {
            return (
              <ul>
                {allWordpressWpApiMenusMenusItems.edges[0].node.items.map(menuItem => {
                  return (
                    <li>
                      <Link to={menuItem.object_slug} className="nav-link">{menuItem.title}</Link>
                    </li>
                  )
                })}
              </ul>
            )
          }
        }}
      />
      </nav>
      <div className="register">
        <RegisterForm />
      </div>
      <header className={this.state.scrolling ? 'transparent' : 'solid'}>
        <Link to="/">
          <div className='logo' dangerouslySetInnerHTML={{ __html: svgLogo }} />
        </Link>
        <button onClick={this.navToggle} className="header_toggles nav-toggle">{this.state.isToggleOn ? 'Menu' : 'Close'}</button>
        <button onClick={this.registerToggle} className="header_toggles register-toggle">{this.state.isRegisterToggleOn ? 'Register' : 'Close'}</button>
      </header>
      </>
    )
  }
}
export default Header