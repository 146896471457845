import React from 'react';
import { graphql } from 'gatsby';
import './style.scss'
import RegisterForm from '../../global/header/register-consumer'

const RegisterComponent = ({
  content
}) => (
  <section className="RegisterComponent" id="footer_register">
    <div className="container-fluid">
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      <div className="register">
        <RegisterForm />
      </div>
    </div>
  </section>
);

export default RegisterComponent;