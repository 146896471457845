import React from 'react';
import {graphql, Link} from 'gatsby';
import './style.scss'

export const fragment = graphql `
  fragment FullWidthImage on WordPressAcf_full_width_image {
    image {
      source_url
    }
  }
`;

function FullWidthImage({image}){
    return(
      <section className="FullWidthImage">
        <div className="image">
          <img src={ image.source_url } />
        </div>
      </section>
    )
}

export default FullWidthImage;