import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import {Controller, Scene} from 'react-scrollmagic';
import LandMap from './land_map'
import LandNav from './land_nav'

import './style.scss'

export const fragment = graphql `
  fragment LandComponent on WordPressAcf_land_component {
    heading
    pos_image{
      source_url
    }
    stage_to_show
  }
`;

const landItems = graphql`
  {
    allWordpressWpLandLots {
      edges {
        node {
          title
          stage
          acf {
            content          
          }
        }
      }
    }
  }
`

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="10.87" height="15.802" viewBox="0 0 10.87 15.802">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(290.522 -1101.992) rotate(90)">
  <line id="Line_25" data-name="Line 25" x2="13.603" transform="translate(1101.992 284.989)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1111.83 289.992) rotate(-90)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
</g>
</svg>`

function activeItem(e) {
  const ele = e.target.parentElement
  const eleId = e.target.dataset.lot
  const svg = document.querySelector(`#-lot`+eleId)
  console.log(eleId)
  if(ele.classList.contains('active')){
    ele.classList.remove('active')
  } else {
    ele.classList.add('active')
  }
  clearItems(ele)
}

function clearItems(clearItem){
  console.log('clearing items');
  const ele = document.querySelectorAll('.land__list-item')
  ele.forEach(element => {
    if(element !== clearItem){
      element.classList.remove('active');
    }
  });
}
    
// const svgHeight = document.getElementById('svgLandLots').clientHeight
// console.log(svgHeight)

const LandComponent = ({heading, pos_image, stage_to_show}) => (
  <>
  <section className="LandComponent" id={heading.replace(/\s+/g, '-').toLowerCase()}>
      <div className="land__image">
        <h1>{heading}</h1>
        <div className="map">
			<img src={pos_image.source_url} />
		</div>
      </div>
      <div className="land__items">
        <h4>Now Selling {heading}</h4>
        <StaticQuery
          query={landItems}
          render={data => {
            if (data) {
              const items = data.allWordpressWpLandLots.edges.filter(land => land.node.stage.includes(parseInt(stage_to_show)))
                return (
                  <div className="land__list">
                    {items.map(node => {
                      const item = node.node;
                      const id = item.title.toLowerCase().replace(/ /g,"_")
                      const acf = node.node.acf
                      return(
                        <div className="land__list-item">
                          <button type="button" data-lot={id} onClick={e => activeItem(e)}>
                            {item.title}
                            <div className="arrow" dangerouslySetInnerHTML={{ __html: arrow }}/>
                          </button>
                          <div className="land__list-item-content">
                            <div className="inside">
                            <div dangerouslySetInnerHTML={{ __html: acf.content }} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
            }
          }}
        />
    </div>
  </section>
  </>
);

export default LandComponent;