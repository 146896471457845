import React from 'react';
import { graphql } from 'gatsby';
import './style.scss'

export const fragment = graphql`
  fragment ContentGrid on WordPressAcf_content_grid {
    content_item {
      column_width
      content
    }
    module_id
  }
`;

const ContentGridComponent = ({
  content_item,
  module_id
}) => (
  <section className="ContentGridComponent" id={module_id ? module_id : 'no-id'}>
    <div className="container-fluid">
      <div className="content__block">
    {content_item.map(({content, column_width}) => {
      return (
        <div className="content__block-item" data-width={column_width}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      )
    })}
    </div>
    </div>
  </section>
);

export default ContentGridComponent;