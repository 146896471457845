import React from "react"
import { graphql } from 'gatsby'
import Cookies from 'universal-cookie';

import Layout from "./layout"
import SEO from "../components/global/seo"

// Import ACF Modules
import PageHeroComponent from "../components/modules/PageHeroComponent"
import MapComponentHover from "../components/modules/MapComponentHover"
import MapComponentGoogle from "../components/modules/MapComponentGoogle"
import ImageTextComponent from "../components/modules/ImageTextComponent"
import ContentGridComponent from "../components/modules/ContentGridComponent"
import LifestyleBlockComponent from "../components/modules/LifestyleBlockComponent"
import LandComponent from "../components/modules/LandComponent"
import HomeHeroComponent from "../components/modules/HomeHeroComponent"
import RegisterComponent from "../components/modules/RegisterComponent"
import Accordions from "../components/modules/Accordions"
import ImageCollage from "../components/modules/ImageCollage"
import FullWidthImage from "../components/modules/FullWidthImage"
import YouTubeVideo from "../components/modules/YouTubeVideo"
import LandNav from "../components/modules/LandComponent/land_nav"

export const pageQuery = graphql `
query($id: String!) {
  wordpressPage(id: { eq: $id }) {
    title
    slug
    acf {
      modules_page {
        __typename
        ... on WordPressAcf_register_component {
          content
        }
        ... on WordPressAcf_home_hero {
          introduction_content
          content
          image{
            source_url
          }
          image_cover{
            source_url
          }
        }
        ... on WordPressAcf_map {
          layout
          google_map {
            source_url
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                }
              }
            }
          }
        }
        ... on WordPressAcf_image_text {
          type
          alignment
          module_heading
          module_content
          module_image {
            source_url
          }
          m_link
          m_link_title
          module_id
        }
        ... on WordPressAcf_anchor_links {
          id
          links {
            link
            name
          }
        }
        ... on WordPressAcf_youtube_video {
          youtube_video_id
        }        
        ... on WordPressAcf_full_width_image {
          image {
            source_url
          }
        }
        ... on WordPressAcf_content_grid {
          content_item {
            column_width
            content
          }
          module_id
        }
        ... on WordPressAcf_lifestyle_block {
          heading
          layout_type
        }
        ... on WordPressAcf_accordion {
          heading
          accordions {
            content
            heading
          }
        }
        ... on WordPressAcf_land_component {
          heading
          pos_image{
            source_url
          }
          stage_to_show
        }
        ... on WordPressAcf_image_collage {
          heading
          collage_images {
            copy
            link
            title
            image {
              source_url
            }
          }          
          image_1 {
            caption
            localFile {
              childImageSharp {
                fluid(quality: 100){
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                }
              }
            }
          }
          image_2 {
            caption
            localFile {
              childImageSharp {
                fluid(quality: 100){
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                }
              }
            }
          }
          image_3 {
            caption
            localFile {
              childImageSharp {
                fluid(quality: 100){
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                }
              }
            }
          }
          image_4 {
            caption
            localFile {
              childImageSharp {
                fluid(quality: 100){
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                }
              }
            }
          }
        }
      }
    }
  }
}
`

const cookies = new Cookies();
class Page extends React.Component {
  componentDidMount(){
    cookies.set('returning', 'true', { path: '/' });
  }
  render() {
    const StaticPage = this.props.data.wordpressPage
    const sections = this.props.data.wordpressPage.acf.modules_page
    const currentPath = this.props.path

      var pageId = `page-`+StaticPage.slug
    return (
      <Layout className="layout">
        <SEO
        title={StaticPage.title}
        id={pageId}
        />
        <div className="modules">
          {sections.map(section => {
            const typeName = section.__typename;

            switch (typeName) {
              case 'WordPressAcf_page_hero':
                return <PageHeroComponent key={section.id} {...section} />;

              case 'WordPressAcf_map':
                if(section.layout === 'hover'){
                  return <MapComponentHover key={section.id} {...section} />;
                } else {
                  return <MapComponentGoogle key={section.id} {...section} />;
                }

              case 'WordPressAcf_image_text':
                return <ImageTextComponent key={section.id} {...section} />;

              case 'WordPressAcf_content_grid':
                return <ContentGridComponent key={section.id} {...section} />;

              case 'WordPressAcf_lifestyle_block':
                return <LifestyleBlockComponent key={section.id} {...section} />;
                
              case 'WordPressAcf_land_component':
                return <LandComponent key={section.id} {...section} />;

              case 'WordPressAcf_home_hero':
                return <HomeHeroComponent key={section.id} {...section} />;

              case 'WordPressAcf_register_component':
                return <RegisterComponent key={section.id} {...section} />;

              case 'WordPressAcf_accordion':
                return <Accordions key={section.id} {...section} />;

              case 'WordPressAcf_image_collage':
                return <ImageCollage key={section.id} {...section} />;

              case 'WordPressAcf_full_width_image':
                return <FullWidthImage key={section.id} {...section} />;

              case 'WordPressAcf_youtube_video':
                return <YouTubeVideo key={section.id} {...section} />;

              case 'WordPressAcf_anchor_links':
                return <LandNav key={section.id} {...section} />;

              default:
                return `Has no modules :(`
            }
          })}
        </div>
        {console.log(StaticPage.acf.modules_page)}
      </Layout>
    )
  }
}

export default Page