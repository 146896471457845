import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import './style.scss'

export const fragment = graphql`
  fragment PageHeroComponent on WordPressAcf_page_hero {
    content
    image {
      localFile {
        childImageSharp {
          fluid {
            srcSetWebp
            srcWebp
            srcSet
          }
        }
      }
    }
  }
`;

const PageHeroComponent = ({
  content,
  image
}) => (
  <section className="PageHeroComponent">
    {(() => {
      if(image){
        return <Img fluid={image.localFile.childImageSharp.fluid} />
      }
    })()}
    <div className="container">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </section>
);

export default PageHeroComponent;