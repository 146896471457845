import React from "react"
import { globalHistory } from '@reach/router';

import Header from "../components/global/header/header"
import Footer from "../components/global/footer/footer"
import HomeIntro from "../components/global/HomeIntro"

const Layout = ({ location, children }) => {
  return (
    <>
      <HomeIntro currentpage={globalHistory.location.pathname}/>
      <Header/>
      <main>{children}</main>
      <Footer/>
    </>
  )
}
export default Layout