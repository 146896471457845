import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import './style.scss'

function activeItem(e) {
    const ele = e.target.parentElement
    const eleId = e.target.dataset.lot
    console.log(eleId)
    if(ele.classList.contains('active')){
        ele.classList.remove('active')
    } else {
        ele.classList.add('active')
    }
    clearItems(ele)
}

function clearItems(clearItem){
    console.log('clearing items');
    const ele = document.querySelectorAll('.accordion')
    ele.forEach(element => {
        if(element !== clearItem){
        element.classList.remove('active');
        }
    });
}

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="10.87" height="15.802" viewBox="0 0 10.87 15.802">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(290.522 -1101.992) rotate(90)">
  <line id="Line_25" data-name="Line 25" x2="13.603" transform="translate(1101.992 284.989)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1111.83 289.992) rotate(-90)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
</g>
</svg>`

const AccordionsComponent = ({
  heading,
  accordions
}) => (
  <section className="AccordionsComponent">
    <div className="container">
      <h2 dangerouslySetInnerHTML={{ __html: heading }} />
      <div className="accordions">
        {accordions.map(({heading, content}) => {
            const id = heading.toLowerCase().replace(/ /g,"_")

            return (
                <div className="accordion">
                    <button type="button" data-accordion={id} onClick={e => activeItem(e)}>
                        {heading}
                        <div className="arrow" dangerouslySetInnerHTML={{ __html: arrow }}/>
                    </button>
                    <div className="accordion-content">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
            )
        })}
      </div>
    </div>
  </section>
);

export default AccordionsComponent;