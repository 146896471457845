import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="159.22" height="116.721" viewBox="0 0 159.22 116.721">
<g id="Group_127" data-name="Group 127" transform="translate(-699.793 -1089.302) rotate(16)">
  <path id="Path_82" data-name="Path 82" d="M0,18.424,23.1,0,69.618,58.877,45.987,77.137Z" transform="matrix(0.985, 0.174, -0.174, 0.985, 1011.372, 858.795)" fill="#f5f5f5" stroke="#e8e8e8" stroke-width="2"/>
  <path id="Path_83" data-name="Path 83" d="M0,18.424,23.1,0,71.188,58.806,47.169,77.683Z" transform="matrix(0.985, 0.174, -0.174, 0.985, 1036.976, 844.487)" fill="#f5f5f5" stroke="#e8e8e8" stroke-width="2"/>
  <path id="Path_84" data-name="Path 84" d="M0,18.424,23.1,0,71.188,58.806,47.169,77.683Z" transform="matrix(0.985, 0.174, -0.174, 0.985, 1063.184, 830.285)" fill="#f5f5f5" stroke="#e8e8e8" stroke-width="2"/>
  <path id="Path_85" data-name="Path 85" d="M1020.347,905.628l79.308-45.02" transform="translate(3.14 4.257)" fill="none" stroke="#e8e8e8" stroke-width="2"/>
</g>
</svg>
`

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="10.87" height="15.802" viewBox="0 0 10.87 15.802">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(290.522 -1101.992) rotate(90)">
  <line id="Line_25" data-name="Line 25" x2="13.603" transform="translate(1101.992 284.989)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1111.83 289.992) rotate(-90)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
</g>
</svg>
`

export const fragment = graphql `
  fragment LandNav on WordPressAcf_anchor_links {
    links {
        link
        name
    }
  }
`;

const LandNav = ({links}) => {
    return (
        <div className="LandNav">
            {links.map(link => {
                return(
                <AnchorLink href={`#${link.link}`} key={link.name}>
                    <div className="icon" dangerouslySetInnerHTML={{ __html: icon }} />
                    <span>{link.name}</span>
                    <div className="arrow" dangerouslySetInnerHTML={{ __html: arrow }} />
                </AnchorLink>    
                )
            })}
        </div>
    )
}

export default LandNav