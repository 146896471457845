import React from 'react';
import {graphql, Link} from 'gatsby';
import {Controller, Scene} from 'react-scrollmagic';
import Parallax from 'react-rellax';
import './style.scss'

export const fragment = graphql `
  fragment ImageTextComponent on WordPressAcf_image_text {
    alignment
    module_content
    module_heading
    type
    module_image {
      source_url
    }
    module_id
    m_link
    m_link_title
  }
`;

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="50.369" height="10.87" viewBox="0 0 50.369 10.87">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(-1102.426 -966.653)">
  <line id="Line_25" data-name="Line 25" y2="48" transform="translate(1102.426 971.989) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1146.83 976.992) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
</g>
</svg>
`

function ImageTextComponent({alignment, module_content, type, module_image, m_link,m_link_title, module_heading, module_id }){
  if(type === 'white'){
    return(
      <section className="ImageTextComponent" data-color="white" data-alignment={ alignment ? 'left' : 'right' } id={module_id ? module_id : 'no-id'}>
        <div className="image">
          <img src={ module_image.source_url } />
        </div>
        <div className="content">
        {(() => {
            if(module_heading) {
              return (
                <h1 dangerouslySetInnerHTML={{
                  __html: module_heading
                }}/>
                )
            }
          })()}
          <div dangerouslySetInnerHTML={{
            __html: module_content
          }}/>
          {(() => {
            if(m_link) {
              return (
                <Link to={ m_link }>{ m_link_title } <span dangerouslySetInnerHTML={{ __html: arrow }} /></Link>
                )
            }
          })()}
        </div>
      </section>
    )
  } else {
    return(
      <Controller>
        <Scene duration={900} offset={200} classToggle="color">
          <section className="ImageTextComponent" id={module_id ? `${module_id}` : 'n'} data-color="white_color" data-alignment={ alignment ? 'left' : 'right' }>
            <div className="wrap">
            <div className="image">
            <img src={ module_image.source_url } />
            </div>
            <div className="content">
            {(() => {
                if(module_heading) {
                  return (
                    <h1 dangerouslySetInnerHTML={{
                      __html: module_heading
                    }}/>
                    )
                }
              })()}
              <div dangerouslySetInnerHTML={{
                __html: module_content
              }}/>
          {(() => {
            if(m_link) {
              return (
                <Link to={ m_link }>{ m_link_title } <span dangerouslySetInnerHTML={{ __html: arrow }} /></Link>
                )
            }
          })()}          
            </div>
            </div>
          </section>
        </Scene>
      </Controller>
    )
  }
}

export default ImageTextComponent;