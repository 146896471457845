import React from 'react';
import './style.scss'

const MapComponentGoogle = (data) => {
  return (
    <div className="MapComponentGoogle">
      <h1>Local<br />Amenities</h1>
      <img src={data.google_map.source_url} />
    </div>
  )
}

export default MapComponentGoogle;