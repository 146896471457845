import React from 'react';
import {graphql, Link} from 'gatsby';
import './style.scss'

export const fragment = graphql `
  fragment YouTubeVideo on WordPressAcf_youtube_video {
    youtube_video_id
  }
`;

function YouTubeVideo({youtube_video_id}){
    return(
      <section className="YouTubeVideo">
        <div className="video-wrapper">
        <iframe src={`https://www.youtube-nocookie.com/embed/${youtube_video_id}?rel=0&showinfo=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </section>
    )
}

export default YouTubeVideo;