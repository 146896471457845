import React from 'react';
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './style.scss'

const long_arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="69.752" height="241.398" viewBox="0 0 69.752 241.398">
<g id="Large_Arrow" data-name="Large Arrow" transform="translate(16.924 308.302) rotate(90)">
  <line id="Line_25" data-name="Line 25" x2="233" transform="translate(-308.302 -18.415)" fill="none" stroke="#404a3d" stroke-width="8"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,32.05,32.045,64.1,0" transform="translate(-104.605 14.096) rotate(-90)" fill="none" stroke="#404a3d" stroke-width="8"/>
</g>
</svg>
`

class HomeHeroComponent extends React.Component {
  constructor(props){
    super(props)

    this.heroAnimation = gsap.timeline({
      paused:true,
      reversed:true
    })
    this.heroImages = gsap.timeline({
      paused:true,
      reversed:true
    })
  }

  componentDidMount(){
    gsap.registerPlugin(ScrollTrigger);
    this.heroAnimation.to('header',{opacity:1, duration: 1})
    this.heroAnimation.to('.introduction_text',{opacity:1, duration: 1},"-=1")
    setTimeout(() => {
      this.heroAnimation.play();
    }, 5000);

    // Hero Images Animate on scroll
    const height = document.querySelector('.HomeHeroComponent').clientHeight
    if(window.innerWidth > 1024){
      this.heroImages.to('.hide-on-scroll', {
        opacity:0,
        duration: 200
      }).to('.cover', {
        opacity:1,
        duration: 400
      })
    } else {
      this.heroImages.to('.cover', {
        opacity:1,
        duration: 400
      })
    }

  ScrollTrigger.create({
    trigger: '.HomeHeroComponent',
    animation: this.heroImages,
    start: 'top top',
    end: () => '+=' + (height * 1.5) + 'px',
    pin: true,
    anticipatePin: 1,
    scrub: 0.5
  })    
  }

  render(){
    return(
      <section className="HomeHeroComponent">
      <div className="top">
        <div className="hide-on-scroll">
          <div className="introduction_text">
            <h1 dangerouslySetInnerHTML={{ __html: this.props.introduction_content }} />
            <AnchorLink href="#content">
            <div className="arrow" dangerouslySetInnerHTML={{ __html: long_arrow }} />
            </AnchorLink>
          </div>
        </div>
        {(() => {
          if(this.props.image){
            return <img className="initial" src={this.props.image.source_url} />
          }
        })()}
        {(() => {
          if(this.props.image_cover){
            return <img className="cover" src={this.props.image_cover.source_url} />
          }
        })()}
      </div>
      <div className="bottom">
        <div className="content_text" id="content" dangerouslySetInnerHTML={{ __html: this.props.content }} />
      </div>
    </section>
    )
  }
}

export default HomeHeroComponent;