import React from "react"
import {Link} from "gatsby"
// Adding global stylesheet here
import "./_footer.scss"

function Footer() {
const logo = `<svg xmlns="http://www.w3.org/2000/svg" width="145.719" height="18.504" viewBox="0 0 145.719 18.504">
  <g id="Logo" transform="translate(-237.026 641.377)">
    <path id="Path_1" data-name="Path 1"
      d="M247.534-627.51l-3.588,8.127h-2.819l-4.1-17.94h2.742l3.152,14.147,3.581-7.988h2.16l3.587,8.039,3.152-14.2h2.64l-4.126,17.94H251.1Z"
      transform="translate(0 -3.746)" fill="#404a3d" />
    <path id="Path_2" data-name="Path 2"
      d="M562.877-619.383H550.883v-17.94H562.8v2.435h-9.175v3.725h8.534v2.435h-8.534v6.911h9.252Z"
      transform="translate(-290.049 -3.746)" fill="#404a3d" />
    <path id="Path_3" data-name="Path 3" d="M753.927-637.323h2.742v15.505h8.175v2.435H753.927Z"
      transform="translate(-477.69 -3.746)" fill="#404a3d" />
    <path id="Path_4" data-name="Path 4" d="M933.995-637.323h2.742v15.505h8.175v2.435H933.995Z"
      transform="translate(-644.099 -3.746)" fill="#404a3d" />
    <path id="Path_5" data-name="Path 5"
      d="M1106.162-633.509l-1.64-.487c-2.358-.692-3.152-1.307-3.152-2.563,0-1.461,1.41-2.486,3.357-2.486,1.893,0,3.486.871,3.583,3.076h2.67v-.205c-.051-3.127-2.614-5.2-6.253-5.2-3.537,0-6.048,2.05-6.048,4.946,0,2.358,1.281,3.767,4.408,4.716l1.589.487c2.332.718,3.767,1.563,3.767,3.229,0,1.768-1.768,2.768-3.691,2.768-2.2,0-3.954-1.288-4.234-4.1h-2.711c.307,4.69,3.409,6.458,6.92,6.458,3.716,0,6.484-2.127,6.484-5.305C1111.211-630.869,1109.622-632.484,1106.162-633.509Z"
      transform="translate(-795.485 0)" fill="#404a3d" />
    <path id="Path_6" data-name="Path 6"
      d="M1320.183-628.729v9.346h-2.742v-17.94h10.995v2.435h-8.252v3.725h7.484v2.435Z"
      transform="translate(-998.458 -3.746)" fill="#404a3d" />
    <path id="Path_7" data-name="Path 7"
      d="M1488.051-632.176c0-5.356,3.614-9.2,8.483-9.2,4.921,0,8.509,3.819,8.509,9.2,0,5.587-3.742,9.3-8.509,9.3C1491.742-622.873,1488.051-626.564,1488.051-632.176Zm14.147,0c0-3.665-2.255-6.638-5.664-6.638s-5.638,2.947-5.638,6.638c0,3.819,2.307,6.74,5.638,6.74C1499.917-625.436,1502.2-628.332,1502.2-632.176Z"
      transform="translate(-1156.126 0)" fill="#404a3d" />
    <path id="Path_8" data-name="Path 8"
      d="M1762.325-627.507h-2.973v8.124h-2.717v-17.94h7c3.024,0,5.408,1.845,5.408,4.818a4.713,4.713,0,0,1-1.41,3.537,5.038,5.038,0,0,1-2.306,1.23l4.921,8.355h-3.2Zm-2.973-2.409h4.126a2.438,2.438,0,0,0,2.717-2.563,2.5,2.5,0,0,0-2.794-2.409h-4.049Z"
      transform="translate(-1404.336 -3.746)" fill="#404a3d" />
    <path id="Path_9" data-name="Path 9"
      d="M1969.138-637.323h6.023a8.328,8.328,0,0,1,8.3,8.739c0,5.767-3.87,9.2-8.252,9.2h-6.074Zm6.023,15.505c2.973,0,5.459-2.614,5.459-6.766,0-3.819-2.588-6.3-5.536-6.3h-3.2v13.071Z"
      transform="translate(-1600.719 -3.746)" fill="#404a3d" />
  </g>
</svg>
`
const ampleLogo = `<svg xmlns="http://www.w3.org/2000/svg" width="70.687" height="21.903" viewBox="0 0 70.687 21.903">
<g id="Ample_Logo" data-name="Ample Logo" opacity="0.9">
  <path id="Path_12" data-name="Path 12" d="M98.564,103.005c0-3.793,2.65-6.807,6.444-6.807a5.455,5.455,0,0,1,4.365,1.949l.206-1.637h2.106v11.146l.241,1.845H109.6l-.226-1.741a5.457,5.457,0,0,1-4.365,2.053C101.058,109.813,98.564,106.8,98.564,103.005Zm10.99,0a4.386,4.386,0,0,0-4.313-4.7c-2.6,0-4.313,2.079-4.313,4.7s1.715,4.7,4.313,4.7A4.386,4.386,0,0,0,109.554,103.005Z" transform="translate(-98.564 -92.054)" fill="#404a3d"/>
  <path id="Path_13" data-name="Path 13" d="M225.273,98.16a4.255,4.255,0,0,1,3.785-1.962,3.779,3.779,0,0,1,3.845,2.183,4.509,4.509,0,0,1,4.105-2.183c3.222,0,4.677,2.079,4.677,5.4v7.9h-2.338v-8c0-2.183-1.065-3.2-2.65-3.2-1.741,0-3.222,1.585-3.222,5.041V109.5h-2.312v-8c0-2.183-1.065-3.2-2.65-3.2-1.767,0-3.222,1.585-3.222,5.041V109.5h-2.312V96.51h2.08Z" transform="translate(-206.58 -92.054)" fill="#404a3d"/>
  <path id="Path_14" data-name="Path 14" d="M389.308,98.261A5.835,5.835,0,0,1,393.73,96.2c3.949,0,6.366,3.014,6.366,6.807s-2.624,6.807-6.47,6.807a5.309,5.309,0,0,1-4.313-2v5.847l-2.312.3V96.51h2.081Zm8.45,4.745a4.341,4.341,0,1,0-4.339,4.7A4.4,4.4,0,0,0,397.758,103.005Z" transform="translate(-348.984 -92.054)" fill="#404a3d"/>
  <path id="Path_15" data-name="Path 15" d="M504.136,65.054l2.312-.3v15.6l.239,1.845h-2.314l-.237-1.845Z" transform="translate(-450.68 -64.755)" fill="#404a3d"/>
  <path id="Path_16" data-name="Path 16" d="M551.327,106.635a6.175,6.175,0,0,1-5.622,3.178c-4.105,0-6.444-2.962-6.444-6.807,0-3.793,2.416-6.807,6.444-6.807,3.949,0,6.158,2.884,6.158,6.522,0,.312-.026.728-.052,1.039H541.574a4.03,4.03,0,0,0,4.235,3.949,4.145,4.145,0,0,0,3.793-2.338Zm-1.75-4.773a3.649,3.649,0,0,0-3.846-3.56,3.925,3.925,0,0,0-4.131,3.56Z" transform="translate(-481.176 -92.054)" fill="#404a3d"/>
</g>
</svg>
`
return (
<div>
  <div className="agents">
    <div className="agents-logo">
    <img src={'/prd-logo.png'} alt="Logo" />
    </div>
    <div className="agent">
      <div className="agent-details">
        <div className="name">
        Tom Isaacs
        </div>
        <a href="tel:+614417532871">0417 532 871</a>
        <a href="mailto:tomi@prdbendigo.com.au" target="_blank">tomi@prdbendigo.com.au</a>
      </div>
    </div>
    <div className="agent">
      <div className="agent-details">
        <div className="name">
        Sacha Dale
        </div>
        <a href="tel:+614407899066">0407 899 066</a>
        <a href="mailto:sachad@prdbendigo.com.au" target="_blank">sachad@prdbendigo.com.au</a>
      </div>
    </div>
  </div>
  <footer>
    <div className="col col-1">
      <Link to="/">
      <div dangerouslySetInnerHTML={{
            __html: logo
          }} />
      </Link>
    </div>
    <div className="col col-2 logo">
      <a href="https://www.prd.com.au/bendigo/" target="_blank" rel="noopener noreferrer">Project Marketed by PRD</a>
    </div>
    <div className="col col-3">
      <a href="https://raak.com.au" target="_blank" rel="noopener noreferrer">Website Design Melbourne by Raak</a>
    </div>
    <div className="col col-4">
    <div className="anotherAmple">
        <a href="https://ampleig.com.au" target="_blank" rel="noopener noreferrer">Another development by
          <div dangerouslySetInnerHTML={{
            __html: ampleLogo
          }}/></a>          
        </div>
    </div>
  </footer>
</div>
)
}
export default Footer