import React from 'react';
import { gsap } from 'gsap'
import './style.scss'

class HomeIntro extends React.Component {
    constructor(props) {
      super(props);
      this.state = {homeIntroAnimation: true};
      // this.state = {isRegisterToggleOn: true};
  
      // This binding is necessary to make `this` work in the callback
      this.homeIntroAnimation = this.homeIntroAnimation.bind(this);
  
      this.hiTL = gsap.timeline({
        paused: true,
        reversed: true
      });
    }
    componentDidMount(){
        this.hiTL.to('.HomeIntro-inside',{top:0, duration: 1})
        this.hiTL.to('.HomeIntro',{opacity:0,autoAlpha:0, duration: 0.5,delay:1.5})
        this.hiTL.to('.modules,footer',{opacity:1, duration: 0.5})
        
        
        setTimeout(() => {
            this.hiTL.play()
        }, 500);
    }
    homeIntroAnimation(){
        this.setState(function(prevState) {
            return {homeIntroAnimation: !prevState.homeIntroAnimation};
        });
    }
    render(){
        const currentPath = this.props.currentpage
        console.log(currentPath)
        // const logo = `<?xml version="1.0" encoding="utf-8"?>
        // <!-- Generator: Adobe Illustrator 23.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        // <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        //      viewBox="0 0 385.2 113.9" style="enable-background:new 0 0 385.2 113.9;" xml:space="preserve">
        // <style type="text/css">
        //     .st0{fill:#465059;}
        //     .st1{enable-background:new    ;}
        //     .st2{fill:#393939;}
        // </style>
        // <g id="Group_14" transform="translate(767.676 447.125)">
        //     <path id="Path_40" class="st0" d="M-686.4-367.7c0-12,8.4-21.5,20.3-21.5c7,0,11.7,3.4,13.8,6.1l0.6-5.2h6.6v35.1l0.8,5.8h-7.3
        //         l-0.7-5.5c-2.3,3.4-7.6,6.5-13.8,6.5C-678.6-346.3-686.4-355.8-686.4-367.7z M-651.8-367.7c0-8.3-5.5-14.8-13.6-14.8
        //         c-8.2,0-13.6,6.5-13.6,14.8s5.4,14.8,13.6,14.8C-657.3-352.9-651.8-359.5-651.8-367.7z"/>
        //     <path id="Path_41" class="st0" d="M-627.5-383c2.6-4,7.1-6.4,11.9-6.2c6.5,0,10.1,2.5,12.1,6.9c2-3.5,6.1-6.9,12.9-6.9
        //         c10.2,0,14.7,6.5,14.7,17v24.9h-7.4v-25.2c0-6.9-3.4-10.1-8.4-10.1c-5.5,0-10.2,5-10.2,15.9v19.4h-7.3v-25.2
        //         c0-6.9-3.4-10.1-8.4-10.1c-5.6,0-10.2,5-10.2,15.9v19.4h-7.3v-40.9h6.6L-627.5-383z"/>
        //     <path id="Path_42" class="st0" d="M-559.4-382.7c3.5-4.1,8.6-6.5,13.9-6.5c12.4,0,20.1,9.5,20.1,21.4c0,12-8.3,21.5-20.4,21.5
        //         c-5.3,0.2-10.3-2.2-13.6-6.3v18.4l-7.3,0.9v-55h6.6L-559.4-382.7z M-532.7-367.7c0-8.3-5.6-14.8-13.6-14.8
        //         c-8.1,0-13.7,6.6-13.7,14.8c0,8.3,5.5,14.8,13.6,14.8C-538.2-352.9-532.7-359.5-532.7-367.7L-532.7-367.7z"/>
        //     <path id="Path_43" class="st0" d="M-518-401.3l7.3-0.9v49.2l0.8,5.8h-7.3l-0.7-5.8V-401.3z"/>
        //     <path id="Path_44" class="st0" d="M-465.4-356.3c-3.3,5.8-9.3,10-17.7,10c-12.9,0-20.3-9.3-20.3-21.5c0-12,7.6-21.4,20.3-21.4
        //         c12.4,0,19.4,9.1,19.4,20.5c0,1-0.1,2.3-0.2,3.3h-32.3c0.6,7.5,5.7,12.4,13.3,12.4c5.4,0,9.3-2.6,12-7.4L-465.4-356.3z
        //          M-470.9-371.3c-0.6-6.7-5.1-11.2-12.1-11.2c-7.1,0-12.3,4.5-13,11.2H-470.9z"/>
        // </g>
        // <g class="st1">
        //     <path class="st2" d="M10.7,8.5H3.1l-1.6,3H0L6.2,0.3h1.5l6.2,11.2h-1.5L10.7,8.5z M10,7.3L6.9,1.6L3.8,7.3H10z"/>
        //     <path class="st2" d="M28.2,1.6v3.9h7.9v1.2h-7.9v4.8h-1.3V0.3h10.7v1.2H28.2z"/>
        //     <path class="st2" d="M54.8,0.3v5.9c0,3.3-2,5.6-5.9,5.6C45,11.9,43,9.5,43,6.3V0.3h1.3v5.8c0,2.8,1.6,4.5,4.6,4.5
        //         c2.9,0,4.6-1.7,4.6-4.5V0.3H54.8z"/>
        //     <path class="st2" d="M71.9,1.6h-5.3v10h-1.3v-10H60V0.3h11.9V1.6z"/>
        //     <path class="st2" d="M88.9,0.3v5.9c0,3.3-2,5.6-5.9,5.6c-3.9,0-5.9-2.4-5.9-5.6V0.3h1.3v5.8c0,2.8,1.6,4.5,4.6,4.5
        //         c2.9,0,4.6-1.7,4.6-4.5V0.3H88.9z"/>
        //     <path class="st2" d="M106,11.5l-3.7-4.3h-5.3v4.3h-1.4V0.3h7.5c2.5,0,4.3,1.2,4.3,3.5c0,2-1.4,3.2-3.5,3.4l3.8,4.3H106z M103,6
        //         c1.8,0,3-0.7,3-2.2s-1.2-2.2-3-2.2h-6.1V6H103z"/>
        //     <path class="st2" d="M124.4,10.3v1.2h-10.8V0.3h10.7v1.2h-9.3v3.6h7.9v1.2h-7.9v3.8H124.4z"/>
        //     <path class="st2" d="M147.2,8.5h-7.6l-1.6,3h-1.5l6.2-11.2h1.5l6.2,11.2h-1.5L147.2,8.5z M146.6,7.3l-3.1-5.7l-3.1,5.7H146.6z"/>
        //     <path class="st2" d="M169.8,0.3v11.2h-1.3V2.2l-5.7,6.9h-0.1L157,2.2v9.3h-1.3V0.3h1.5l5.6,6.9l5.6-6.9H169.8z"/>
        //     <path class="st2" d="M188.5,3.9c0,2.2-1.7,3.6-4.3,3.6h-6.1v4.1h-1.3V0.3h7.5C186.8,0.3,188.5,1.6,188.5,3.9z M187.1,3.9
        //         c0-1.6-1.2-2.3-3-2.3h-6.1v4.6h6.1C186,6.2,187.1,5.5,187.1,3.9z"/>
        //     <path class="st2" d="M204.2,10.3v1.2h-9.9V0.3h1.3v10H204.2z"/>
        //     <path class="st2" d="M220.4,10.3v1.2h-10.8V0.3h10.7v1.2h-9.3v3.6h7.9v1.2h-7.9v3.8H220.4z"/>
        //     <path class="st2" d="M245.1,8.5l1.3,0.6c-1.1,1.7-3,2.8-5.7,2.8c-4.2,0-6.7-2.3-6.7-5.9c0-3.6,2.6-5.9,6.8-5.9
        //         c2.7,0,4.6,1.1,5.7,2.8l-1.3,0.6c-0.9-1.5-2.5-2.1-4.4-2.1c-3.2,0-5.4,1.6-5.4,4.7c0,3.1,2.1,4.7,5.4,4.7
        //         C242.6,10.6,244.2,10,245.1,8.5z"/>
        //     <path class="st2" d="M251.2,5.9c0-3.6,2.6-5.9,6.8-5.9c4.3,0,6.8,2.3,6.8,5.9s-2.6,5.9-6.8,5.9C253.7,11.9,251.2,9.6,251.2,5.9z
        //          M263.4,5.9c0-3-2.1-4.7-5.4-4.7c-3.3,0-5.4,1.7-5.4,4.7c0,3,2.1,4.7,5.4,4.7C261.3,10.6,263.4,8.9,263.4,5.9z"/>
        //     <path class="st2" d="M285,0.3v11.2h-1.3V2.2L278,9.1h-0.1l-5.7-6.9v9.3h-1.3V0.3h1.5l5.6,6.9l5.6-6.9H285z"/>
        //     <path class="st2" d="M306.1,0.3v11.2h-1.3V2.2L299,9.1h-0.1l-5.7-6.9v9.3h-1.3V0.3h1.5l5.6,6.9l5.6-6.9H306.1z"/>
        //     <path class="st2" d="M324.6,0.3v5.9c0,3.3-2,5.6-5.9,5.6c-3.9,0-5.9-2.4-5.9-5.6V0.3h1.3v5.8c0,2.8,1.6,4.5,4.6,4.5
        //         c2.9,0,4.6-1.7,4.6-4.5V0.3H324.6z"/>
        //     <path class="st2" d="M343.2,0.3v11.2H342L332.6,2v9.5h-1.3V0.3h1.5l9.1,9.2V0.3H343.2z"/>
        //     <path class="st2" d="M350.1,11.5V0.3h1.3v11.2H350.1z"/>
        //     <path class="st2" d="M368.8,1.6h-5.3v10h-1.3v-10h-5.3V0.3h11.9V1.6z"/>
        //     <path class="st2" d="M374.2,0.3l4.7,6.1l4.7-6.1h1.6l-5.6,7.3v3.9h-1.3V7.6l-5.6-7.3H374.2z"/>
        // </g>
        // </svg>
        // `
        const logo = `		<div class="introduction__text--wrap">
        <!-- Tag Line -->
        <svg class="introduction__text--item" version="1.1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
            x="0px" y="0px" width="453.9px" height="14.2px" viewBox="0 0 453.9 14.2" style="enable-background:new 0 0 453.9 14.2;"
            xml:space="preserve">
            <style type="text/css">
                .st0{fill:#333E48;}
            </style>
            <g>
                <path class="st0" d="M438.9,0.4l6.7,8.7v4.7h1.6V9.1l6.7-8.7H452l-5.6,7.2l-5.6-7.2H438.9z M434.3,0.4h-14.2v1.5h6.3v11.9h1.6V1.9
                    h6.3V0.4z M413.6,0.4H412v13.4h1.6V0.4z M402.2,0.4v11l-10.9-11h-1.7v13.4h1.6V2.4l11.2,11.3h1.4V0.4H402.2z M380,0.4v7
                    c0,3.3-2,5.3-5.4,5.3c-3.5,0-5.5-2-5.5-5.3v-7h-1.6v7.1c0,3.9,2.5,6.7,7.1,6.7c4.6,0,7-2.8,7-6.7V0.4H380z M357.8,0.4l-6.7,8.2
                    l-6.7-8.2h-1.8v13.4h1.6V2.7l6.8,8.2h0.1l6.8-8.2v11.1h1.6V0.4H357.8z M332.7,0.4L326,8.6l-6.7-8.2h-1.8v13.4h1.6V2.7l6.8,8.2h0.1
                    l6.8-8.2v11.1h1.6V0.4H332.7z M302.1,12.7c-3.9,0-6.5-2-6.5-5.6c0-3.6,2.6-5.6,6.5-5.6s6.5,2,6.5,5.6
                    C308.6,10.7,306,12.7,302.1,12.7 M302.1,14.2c5.1,0,8.1-2.7,8.1-7.1c0-4.4-3.1-7.1-8.1-7.1C297,0,294,2.7,294,7.1
                    C294,11.4,297,14.2,302.1,14.2 M281.4,12.7c-3.9,0-6.4-1.9-6.4-5.6c0-3.7,2.6-5.6,6.4-5.6c2.3,0,4.2,0.8,5.3,2.6l1.5-0.8
                    c-1.3-2-3.6-3.3-6.8-3.3c-5,0-8.1,2.7-8.1,7.1c0,4.3,3.1,7.1,8,7.1c3.3,0,5.6-1.3,6.9-3.3l-1.5-0.8
                    C285.7,11.9,283.8,12.7,281.4,12.7 M246,12.3V7.7h9.4V6.2H246V1.9h11.1V0.4h-12.7v13.4h12.9v-1.5H246z M227.7,12.3V0.4h-1.6v13.4
                    h11.8v-1.5H227.7z M214,7.4h-7.2V1.9h7.2c2.2,0,3.6,0.9,3.6,2.7S216.2,7.4,214,7.4 M214.1,0.4h-8.9v13.4h1.6V8.9h7.3
                    c3.1,0,5.1-1.6,5.1-4.2C219.2,2,217.2,0.4,214.1,0.4 M195.2,0.4l-6.7,8.2l-6.7-8.2H180v13.4h1.6V2.7l6.8,8.2h0.1l6.8-8.2v11.1h1.6
                    V0.4H195.2z M161.7,8.7l3.7-6.9l3.7,6.9H161.7z M171.9,13.8h1.8l-7.4-13.4h-1.8l-7.4,13.4h1.8l1.9-3.6h9L171.9,13.8z M130,7.2V1.9
                    h7.3c2.2,0,3.6,0.8,3.6,2.6c0,1.8-1.4,2.6-3.6,2.6H130z M142.9,13.8l-4.6-5.2c2.5-0.3,4.1-1.7,4.1-4.1c0-2.6-2.1-4.1-5.1-4.1h-9
                    v13.4h1.6V8.6h6.4l4.4,5.1H142.9z M109.9,12.3V7.7h9.4V6.2h-9.4V1.9h11.1V0.4h-12.7v13.4h12.9v-1.5H109.9z M98.5,0.4v5.8h-11V0.4
                    h-1.6v13.4h1.6v-6h11v6h1.6V0.4H98.5z M79.4,0.4H65.2v1.5h6.3v11.9h1.6V1.9h6.3V0.4z M52.4,12.7c-3.9,0-6.5-2-6.5-5.6
                    c0-3.6,2.6-5.6,6.5-5.6c3.9,0,6.5,2,6.5,5.6C58.9,10.7,56.4,12.7,52.4,12.7 M52.4,14.2c5.1,0,8.1-2.7,8.1-7.1
                    c0-4.4-3.1-7.1-8.1-7.1s-8.1,2.7-8.1,7.1C44.3,11.4,47.4,14.2,52.4,14.2 M35.5,0.4v11l-10.9-11h-1.7v13.4h1.6V2.4l11.2,11.3H37V0.4
                    H35.5z M4.5,8.7l3.7-6.9L12,8.7H4.5z M14.7,13.8h1.8L9.1,0.4H7.4L0,13.8h1.8l1.9-3.6h9L14.7,13.8z"/>
            </g>
        </svg>

        <!-- Logo -->
        <div class="introduction__text--caption">
            <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                x="0px" y="0px" width="240.2px" height="74.4px" viewBox="0 0 240.2 74.4" style="enable-background:new 0 0 240.2 74.4;"
                xml:space="preserve">
                <style type="text/css">
                .st0{fill:#333E48;}
                </style>
                <g>
                <g>
                    <path class="st0" d="M37.3,37.2c0-8.9-5.9-16-14.7-16c-8.8,0-14.7,7.1-14.7,16s5.8,16,14.7,16C31.4,53.2,37.3,46.1,37.3,37.2
                            M0,37.2c0-12.9,9-23.1,21.9-23.1c7.6,0,12.6,3.7,14.8,6.6l1.1-5.6h6.7V53l1,6.3h-7.7l-1.1-5.9c-2.5,3.6-8.2,7-14.8,7
                        C8.5,60.3,0,50.1,0,37.2"/>
                    <path class="st0" d="M54.7,15.1h7.6l1.1,5.6c2.6-4,6.6-6.7,12.9-6.7c7,0,10.9,2.7,13.1,7.4c2.2-3.8,6.6-7.4,13.9-7.4
                        c10.9,0,15.9,7.1,15.9,18.4v26.8h-7.9V32.1c0-7.4-3.6-10.9-9-10.9c-5.9,0-10.9,5.4-10.9,17.1v20.9h-7.9V32.1
                        c0-7.4-3.6-10.9-9-10.9c-6,0-10.9,5.4-10.9,17.1v20.9h-7.9V21.4L54.7,15.1z"/>
                    <path class="st0" d="M165.7,37.2c0-8.9-6-16-14.7-16c-8.7,0-14.7,7.1-14.7,16c0,8.9,5.9,16,14.7,16
                        C159.8,53.2,165.7,46.1,165.7,37.2 M128.2,15.1h7.6l1,6.3c2.8-3.8,8.7-7.3,15.3-7.3c13.4,0,21.6,10.2,21.6,23.1s-8.9,23.1-22,23.1
                        c-7.6,0-12.5-3.9-14.7-6.8v19.7l-7.9,1.2v-53L128.2,15.1z"/>
                </g>
                <polygon class="st0" points="181.6,1.2 189.5,0 189.5,53 190.4,59.3 182.6,59.3 181.6,53 	"/>
                <path class="st0" d="M232.4,33.3c-0.6-7.2-5.5-12.1-13.1-12.1c-7.7,0-13.2,4.9-14,12.1H232.4z M238.3,49.5
                    c-3.5,6.3-10,10.8-19.1,10.8c-13.9,0-21.9-10.1-21.9-23.1c0-12.9,8.2-23.1,21.9-23.1c13.4,0,20.9,9.8,20.9,22.2
                    c0,1.1-0.1,2.5-0.2,3.5h-34.8c0.6,8.1,6.2,13.4,14.4,13.4c5.8,0,10-2.8,12.9-7.9L238.3,49.5z"/>
                </g>
            </svg>
        </div>
    </div>`
        
        if(currentPath === `/`)
        {
            return (
                <div className="HomeIntro">
                    <div className="HomeIntro-inside">
                    <div className='logo' dangerouslySetInnerHTML={{ __html: logo }} />
                    </div>
                </div>
            )
        } else {
            return false
        }
    }
}

export default HomeIntro